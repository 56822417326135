<template>
    <router-view></router-view>
    <h1>Report Price Overseas per Costumer</h1>
    <hr/>
    <bs-error-message :errors="errorMessages"/>
  
    <div class="row justify-content-between">
      <div class="col-md-6">
        <div class="d-grid gap-3 d-md-block">
          <router-link to="/report-price-overseas/create" class="btn btn-primary btn-sm me-md-1">
            <i class="bi bi-file-earmark"></i> Create
          </router-link>
  
        </div>
      </div>
      <div class="col-md-6">
        <div class="pagination-right">
          <bs-pagination :pageNumber="pageNumber" :perPage="perPage" :totalPage="totalPage" :totalRow="totalRow"
                         @toPage="toPage"/>
        </div>
      </div>
    </div>
  
    <div class="">
      <table class="table table-striped">
        <thead>
            <tr>
                <th rowspan="2">
                    Action
                </th>
                <th>
                    <bs-ordering property="SpName" @reorder="reorder">Customer Name</bs-ordering>
                </th>
                <th>
                    <bs-ordering property="iata" @reorder="reorder">IATA</bs-ordering>
                </th>
                <th>
                  <bs-ordering property="BasePriceName" @reorder="reorder">Base Price Name</bs-ordering>
                </th>
                <th>
                  <bs-ordering property="BasePriceQty" @reorder="reorder">Base Price Qty</bs-ordering>
                </th>
                <th>
                  <bs-ordering property="Differential" @reorder="reorder">Differential</bs-ordering>
                </th>
                <th>
                  <bs-ordering property="FuelPrice" @reorder="reorder">Fuel Price</bs-ordering>
                </th>
                <th>
                  <bs-ordering property="FeeName" @reorder="reorder">Fee Name</bs-ordering>
                </th>
                <th>
                  <bs-ordering property="FeeValue" @reorder="reorder">Fee Value</bs-ordering>
                </th>
                <th>
                  <bs-ordering property="TotalPrice" @reorder="reorder">Total Price</bs-ordering>
                </th>
                <th>
                  <bs-ordering property="Currency" @reorder="reorder">Currency</bs-ordering>
                </th>
                <th>
                  <bs-ordering property="SalesUnit" @reorder="reorder">Sales Unit</bs-ordering>
                </th>
                <th>
                  <bs-ordering property="Validity Start" @reorder="reorder">Validity Start</bs-ordering>
                </th>
                <th>
                  <bs-ordering property="Validity End" @reorder="reorder">Validity End</bs-ordering>
                </th>
            </tr>
<!--            <tr>-->
<!--                <th>-->
<!--                    <div>-->
<!--                        <bs-date-picker placeholder="start_date_from" v-model="filter.startDateFrom" @change="search()" />-->
<!--                        <bs-date-picker placeholder="start_date_to" v-model="filter.startDateTo" @change="search()" />-->
<!--                    </div>-->
<!--                </th>-->
<!--                <th>-->
<!--                    <div>-->
<!--                        <bs-date-picker placeholder="end_date_from" v-model="filter.endDateFrom" @change="search()" />-->
<!--                        <bs-date-picker placeholder="end_date_to" v-model="filter.endDateTo" @change="search()" />-->
<!--                    </div>-->
<!--                </th>-->
<!--                <th>-->
<!--                    <div>-->
<!--                        <bs-textbox placeholder="currency_from" v-model="filter.CurrencyFromCodeContains" @keyup="search()" />-->
<!--                    </div>-->
<!--                </th>-->
<!--                <th>-->
<!--                    <div>-->
<!--                        <bs-textbox placeholder="currency_to" v-model="filter.CurrencyToCodeContains" @keyup="search()" />-->
<!--                    </div>-->
<!--                </th>-->
<!--                <th>-->
<!--                    <div>-->
<!--                        <bs-textbox placeholder="convertion_rate" v-model="filter.ConversionRateEquals" @keyup="search()" />-->
<!--                    </div>-->
<!--                </th>-->
<!--                <th>-->
<!--                    <div>-->
<!--                        <bs-textbox placeholder="supplier" v-model="filter.SupplierNameContains" @keyup="search()" />-->
<!--                    </div>-->
<!--                </th>-->
<!--                <th>-->
<!--                    <div>-->
<!--                        <bs-textbox placeholder="location" v-model="filter.LocationNameContains" @keyup="search()" />-->
<!--                    </div>-->
<!--                </th>-->
<!--                <th>-->
<!--                    <div>-->
<!--                        <bs-textbox placeholder="source" v-model="filter.SourceContains" @keyup="search()" />-->
<!--                    </div>-->
<!--                </th>-->
<!--            </tr>-->
        </thead>
        <tbody>
        <template v-if="data != null && data.length > 0">
          <tr v-for="item in data" :key="item.id">
            <td>
              <div class="btn-group">
                <button class="btn btn-sm btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bi bi-menu-down"></i> Action
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <button @click="detailItem(item)" class="dropdown-item"><i class="bi bi-eye"></i> View Details
                    </button>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              {{ item.spName ?? '-'}}
            </td>
            <td>
              {{ item.iata ?? '-'}}
            </td>
            <td>
              {{ item.basePriceName ?? '-'}}
            </td>
            <td>
              {{ item.basePriceQty ?? '-'}}
            </td>
            <td>
              {{ item.differential ?? '-'}}
            </td>
            <td>
              {{ item.fuelPrice ?? '-'}}
            </td>
            <td>
              {{ item.feeName ?? '-'}}
            </td>
            <td>
              {{ item.feeValue ?? '-'}}
            </td>
            <td>
              {{ item.totalPrice ?? '-'}}
            </td>
            <td>
              {{ item.currency ?? '-'}}
            </td>
            <td>
              {{ item.salesUnit ?? '-'}}
            </td>
            <td>
              <view-date v-model="item.validityStartDate"/>
            </td>
            <td>
              <view-date v-model="item.validityEndDate"/>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import ReportPriceOverseasService from "@/services/report-price-overseas-service.js";
  import ViewDate from "@/components/ViewDate.vue";
  // import BsDatePicker from "@/plugins/components/BsDatePicker.vue";

  export default {
    components: {ViewDate},
    data: function () {
      return {
        filter: {
          spNameContains: '',
          iataContains: '',
          basePriceNameContains: '',
          basePriceQtyContains: '',
          differentialContains: '',
          fuelPriceContains: '',
          feeNameContains: '',
          feeValueContains: '',
          totalPriceContains: '',
          currencyContains: '',
          salesUnitContains: '',
          validityStartDateFrom: null,
          validityStartDateTo: null,
          validityEndDateFrom: null,
          validityEndDateTo: null,
          page: 1,
          perPage: 10,
          orderByProperty: 'id',
          orderType: 0
        },
        errorMessages: null,
        pageNumber: 1,
        perPage: 10,
        totalPage: 100,
        totalRow: 0,
        data: Array()
      }
    },
    mounted() {
      this.loadData();
    },
    methods: {
      async toPage(pageNumber) {
        this.filter.page = pageNumber;
        this.loadData();
      },
      async reorder(property, orderType) {
        this.filter.orderByProperty = property;
        this.filter.orderType = orderType;
        this.loadData();
      },
      async search() {
        this.filter.page = 1;
        this.loadData();
      },
      async loadData() {
        const result = await ReportPriceOverseasService.getList(this.filter);
        if (result.status) {
          this.errorMessages = null;
        } else {
          this.errorMessages = result.errorMessages;
        }
        const data = result.data;
        this.pageNumber = data.pageNumber;
        this.perPage = data.perPage;
        this.totalPage = data.totalPage;
        this.totalRow = data.totalRow;
        this.data = data.data;
      },
      detailItem(item) {
        console.log(item)
        // this.$router.push(`/report-price-overseas/detail/${item.id}`);
      },
    },
    watch: {
      $route: function () {
        if (this.$route.path == "/report-price-overseas") {
          this.loadData();
        }
      }
    },
  }
  </script>
  
  <style>
  
  </style>